

/*Font tutorial: https://www.youtube.com/watch?v=arfDRUIZOiw&ab_channel=TheNetNinja  */

/*Theme reference https://validthemes.live/themeforest/appku/index-op-2.html*/

/*Namecheap dns setup: https://www.namecheap.com/support/knowledgebase/article.aspx/9607/2210/how-to-set-up-dns-records-for-your-domain-in-cloudflare-account/*/

/*Deply with cloudflare: https://developers.cloudflare.com/pages/framework-guides/deploy-a-react-site/*/

/*Tailwind breakpoint reference: https://tailwindcss.com/docs/responsive-design*/

/*Loading skeleton: https://www.npmjs.com/package/react-loading-skeleton*/

/*Emmet remove tag ctrl K + ctrl H*/
/*Remove unused imports ctrl K + ctrl N*/


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
 
    /*##############BEGIN TEXT#############*/
    h1 {
        @apply mb-2 text-3xl font-bold;
    }
    h2 {
        @apply mb-2 font-bold
        text-base
        lg:text-xl;
    }

    /*##############END TEXT#############*/
    /*##############BEGIN BUTTON#############*/

   
    .btn {
        @apply
          rounded
          px-5
          py-2.5
          bg-transparent
          hover:bg-gradient-to-r
          hover:ring-2
          hover:ring-offset-2
          hover:ring-slate-400
          transition-all
          ease-out
          duration-300
         
          
      }
      
      .btn span:first-child {
        @apply
          translate-x-12
          rotate-12
          transition-all
          duration-100
          ease-out;
          
      }
      
      .btn span:last-child {
        
        @apply relative;
       
      }
    
    .pill{
        @apply bg-purple-500 p-6 rounded-lg;
    }
    /*##############END BUTTON#############*/

    input[type="text"] {
        @apply p-1 mb-1 border border-slate-300 rounded-sm outline-none;
    }

    /* When mobile nav bar opens, this covers the rest of the screen in a dark tint to draw focus away from it */
    .offLimits {
        @apply fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-75 transition ease-in-out duration-300
  
      }

    
  
}
